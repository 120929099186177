.Three {
  display: block;
  flex-direction: column;
}
.three-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.three-image > img {
  width: 32vw;
}
.three-desc {
  font-size: 1.5vw;
  line-height: 1.6;
  margin-top: -20%;
  text-align: justify;
}
.three-desc > h5 {
  font-weight: 400;
  text-align: justify;
}
.three-desc > h5 > span {
  color: #3e4194;
  font-weight: 700;
  text-align: justify;
}
.three-desc > h5 > span > span {
  color: #f49420;
  font-weight: 700;
  text-align: justify;
}
@media screen and (max-width: 375px) {
  .Three {
    display: block;
    flex-direction: column;
  }
  .three-row {
    display: grid;

    justify-content: center;
    align-items: center;
  }
  .three-image > img {
    width: 90vw;
    height: 221px;
  }
  .three-desc {
    font-size: 0.9rem;
    padding: 0rem 1rem;
    line-height: 1.6;
    text-align: justify;

    width: 90%;
  }
  .three-desc > h5 {
    font-weight: 400;
    text-align: justify;
  }
  .mobile {
    order: 1;
  }
}
@media screen and (max-width: 575px) {
  .Three {
    display: block;
    flex-direction: column;
  }
  .three-row {
    display: grid;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  .three-image > img {
    width: 90vw;
    height: 221px;
  }
  .three-desc {
    font-size: 0.9rem;
    padding: 0rem 1rem;
    line-height: 1.6;
    text-align: justify;

    width: 90%;
  }
  .three-desc > h5 {
    font-weight: 400;
    text-align: justify;
  }
  .mobile {
    order: 1;
  }
}

.Four {
  display: block;
  flex-direction: column;
}
.four-row {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
}
.four-image > img {
  width: 32vw;
}
.four-desc {
  font-size: 1.5vw;
  line-height: 1.6;
  margin-top: -10%;
  text-align: justify;
}
.four-desc > h5 {
  font-weight: 400;
  text-align: justify;
}
@media screen and (max-width: 375px) {
  .Four {
    display: block;
    flex-direction: column;
  }
  .four-row {
    display: grid;

    justify-content: center;
    align-items: center;
  }
  .four-image > img {
    width: 90vw;
    height: 221px;
  }
  .four-desc {
    font-size: 0.9rem;
    padding: 0rem 1rem;
    line-height: 1.6;
    text-align: justify;
    margin-top: -39px;
    width: 90%;
  }
  .four-desc > h5 {
    font-weight: 400;
    text-align: justify;
  }
}
@media screen and (max-width: 575px) {
  .Four {
    display: block;
    flex-direction: column;
  }
  .four-row {
    display: grid;

    justify-content: center;
    align-items: center;
  }
  .four-image > img {
    width: 90vw;
    height: 221px;
  }
  .four-desc {
    font-size: 0.9rem;
    padding: 0rem 1rem;
    line-height: 1.6;
    text-align: justify;

    width: 90%;
  }
  .four-desc > h5 {
    font-weight: 400;
    text-align: justify;
  }
}

.nav-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10vh;
  padding-left: 8vw;
  padding-right: 8vw;
}
.nav-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 23vw;
}
.nav-mail {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  color: black;
  font-weight: 500;
  font-size: 1.2vw;
  text-align: justify;
  justify-content: center;
}
.nav-mail .nav-mail-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  width: 1.53vw;
  height: 3vh;
}

.nav-logo > img {
  justify-content: center;
  align-items: center;
  width: 9vw;
  height: 8vh;
  object-fit: contain;
}
.lang > button {
  border: none;
  padding: 0.9vw 1vw;
  padding-left: 3vw;
  background-color: #f1f1f9;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: 600;
  color: #8d8ba7;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../img/American-flag-icon-vector-PNG.png);
  background-repeat: no-repeat;
  background-size: 1.7vw;
  background-position: 8%;
  font-size: 1vw;
}
@media screen and (max-width: 375px) {
  .nav-logo > img {
    align-items: left;
    justify-content: left;
    width: 70px;
    height: 34px;
  }
  .nav-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 10vh;
  }
  .nav-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 575px) {
  .nav-logo > img {
    align-items: left;
    justify-content: left;
    width: 71px;
    height: 24px;
  }
  .nav-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 10vh;
  }
  .nav-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

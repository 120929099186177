.third {
  display: block;
  flex-direction: column;
}
.t-row {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  justify-content: center;
  align-items: center;
}
.t-image > img {
  width: 20vw;
}
.t-desc {
  font-size: 1.5vw;
  line-height: 1.6;
  text-align: justify;
  width: 30vw;
}
.t-desc > h5 {
  font-weight: 400;
  text-align: justify;
}
@media screen and (max-width: 375px) {
  .third {
    display: block;
    flex-direction: column;
  }
  .t-row {
    display: grid;

    justify-content: center;
    align-items: center;
  }
  .t-image > img {
    width: 80vw;
    height: 30vh;
  }
  .t-desc {
    font-size: 0.9rem;
    padding: 0rem 0.9rem;
    line-height: 1.6;
    text-align: justify;
    margin-top: -40px;
    width: 90%;
  }
  .t-desc > h5 {
    font-weight: 400;
    text-align: justify;
  }
}
@media screen and (max-width: 575px) {
  .third {
    display: block;
    flex-direction: column;
  }
  .t-row {
    display: grid;

    justify-content: center;
    align-items: center;
  }
  .t-image > img {
    width: 65vw;
    height: 30vh;
    margin-left: 50px;
  }
  .t-desc {
    font-size: 0.9rem;
    padding: 0rem 0.9rem;
    line-height: 1.6;
    text-align: justify;
    margin-top: -40px;
    width: 85%;
    margin-left: 7%;
  }
  .t-desc > h5 {
    font-weight: 400;
    text-align: justify;
  }
}

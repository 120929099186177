.core-value-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.core-value-card:nth-last-child(1),
.core-value-card:nth-last-child(2) {
  transform: translateX(50%);
}

.core-value-card .core-value-card-image img {
  width: 21.9vw;
  height: 21vh;
  cursor: pointer;
  position: absolute;
}
.core-value-card .core-value-card-image img:hover {
  display: none;
}
.core-value-card .core-value-card-image:hover .core-value-card-desc p {
  opacity: 1;
}
.core-value-card .core-value-card-desc p {
  width: 18vw;
  height: 12vh;
  position: relative;
  background: #3e4295;
  flex-direction: column;
  align-items: center;
  display: flex;
  border: 1px solid transparent;
  border-radius: 1.4vw;
  color: white;
  justify-content: center;
  text-align: justify;
  opacity: 0;
  font-weight: 700;
  line-height: 1.6;
  font-size: 1vw;
  margin: 0;
  cursor: pointer;
  padding: 2vw;
}
.core-value-card:hover .core-value-card-desc p {
  opacity: 1;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  text-align: justify;
}
@media screen and (max-width: 1668px) {
  .core-value-card .core-value-card-image img {
    height: auto;
  }
  .core-value-card .core-value-card-desc p {
    height: 13vh;
  }
}
@media screen and (max-width: 1568px) {
  .core-value-card .core-value-card-image img {
    height: auto;
  }
  .core-value-card .core-value-card-desc p {
    height: 13.8vh;
  }
}

@media screen and (max-width: 1366px) {
  .core-value-card .core-value-card-image img {
    height: auto;
  }
  .core-value-card .core-value-card-desc p {
    height: 13vh;
  }
}
@media screen and (max-width: 1268px) {
  .core-value-card .core-value-card-desc p {
    height: 13vh;
  }
}
@media screen and (max-width: 1068px) {
  .core-value-card .core-value-card-desc p {
    height: 10.8vh;
  }
}
@media screen and (max-width: 968px) {
  .core-value-card .core-value-card-desc p {
    height: 9.8vh;
  }
}
@media screen and (max-width: 868px) {
  .core-value-card .core-value-card-desc p {
    height: 8.8vh;
  }
}
@media screen and (max-width: 768px) {
  .core-value-card .core-value-card-desc p {
    height: 7vh;
  }
}
@media screen and (max-width: 568px) {
  .core-value-card .core-value-card-desc p {
    height: 6vh;
  }
}

@media screen and (max-width: 375px) {
  .core-value-card .core-value-card-desc p {
    height: 3.8vh;
  }
  .core-value-card {
    display: grid;
    margin-left: 10vw;
    align-items: center;
    justify-content: center;
  }
  .core-value-card:nth-last-child(1),
  .core-value-card:nth-last-child(2) {
    transform: translateX(0%);
  }

  .core-value-card .core-value-card-image img {
    width: 90vw;
    height: 120px;
    cursor: pointer;
    position: absolute;
    align-items: center;
    justify-content: center;
  }
  .core-value-card .core-value-card-image img:hover {
    display: none;
  }
  .core-value-card .core-value-card-image:hover .core-value-card-desc p {
    opacity: 1;
  }
  .core-value-card .core-value-card-desc p {
    width: 85vw;
    height: 115px;
    position: relative;
    background: #3e4295;
    flex-direction: column;
    align-items: center;
    display: flex;
    border: 1px solid transparent;
    border-radius: 20px;
    color: white;
    justify-content: center;
    text-align: justify;
    opacity: 0;
    font-weight: 700;
    line-height: 1.6;
    font-size: 1rem;
    margin: 0;
    cursor: pointer;
  }
  .core-value-card:hover .core-value-card-desc p {
    opacity: 1;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    text-align: justify;
  }
}
@media screen and (max-width: 575px) {
  .core-value-card .core-value-card-desc p {
    height: 3.8vh;
  }
  .core-value-card {
    display: grid;
    margin-left: 8vw;
    align-items: center;
    justify-content: center;
  }
  .core-value-card:nth-last-child(1),
  .core-value-card:nth-last-child(2) {
    transform: translateX(0%);
  }

  .core-value-card .core-value-card-image img {
    width: 80vw;
    height: 120px;
    cursor: pointer;
    position: absolute;
    align-items: center;
    justify-content: center;
  }
  .core-value-card .core-value-card-image img:hover {
    display: none;
  }
  .core-value-card .core-value-card-image:hover .core-value-card-desc p {
    opacity: 1;
  }
  .core-value-card .core-value-card-desc p {
    width: 77vw;
    height: 115px;

    position: relative;
    background: #3e4295;
    flex-direction: column;
    align-items: center;
    display: flex;
    border: 1px solid transparent;
    border-radius: 20px;
    color: white;
    justify-content: center;
    text-align: justify;
    opacity: 0;
    font-weight: 700;
    line-height: 1.6;
    font-size: 1rem;
    margin: 0;
    cursor: pointer;
  }
  .core-value-card:hover .core-value-card-desc p {
    opacity: 1;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    text-align: justify;
  }
}

.fourth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.f-row1 {
  display: flex;
  flex-direction: row;
  gap: 5vw;
  margin-bottom: 3.2vw;
}
.f-img > img {
  width: 21.9vw;
  height: 21vh;
  position: absolute;
}

.f-row2 {
  display: flex;
  flex-direction: row;
  gap: 5vw;
  align-items: center;
}
.content {
  width: 21.9vw;
  height: 21vh;
  position: relative;
  background: #3e4295;
  flex-direction: column;
  align-items: center;
  display: flex;
  border: 1px solid transparent;
  border-radius: 1.4vw;
  color: white;
  justify-content: center;
  text-align: justify;
  opacity: 0;
  font-weight: 700;
  line-height: 1.6;
  font-size: 1vw;
}

.f-row1 :hover .content {
  opacity: 1;
  z-index: 1;
  transition: all 0.2s;
  text-align: justify;
}
.f-row2 :hover.content {
  opacity: 1;
  z-index: 1;
  transition: all 0.2s;
  text-align: justify;
}
.core-values-cards-container {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 4vw;
}
@media screen and (max-width: 375px) {
  .core-values-cards-container {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.8vw;
  }
}
@media screen and (max-width: 575px) {
  .core-values-cards-container {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.8vw;
  }
}

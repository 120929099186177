.nav-select {
  padding: 1vw 2vw;
  width: 8vw;
  background-color: #f1f1f9;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: 600;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../img/American-flag-icon-vector-PNG.png);
  background-repeat: no-repeat;
  background-size: 2vw;
  background-position: 4%;
}
.nav-select option span {
  color: #8d8ba7;
}

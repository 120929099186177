.timeline {
  height: auto;
  margin-top: 100px;
  border: none;
  padding: 2rem 5rem;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
}
.navline {
  padding: 0;
}
ul.navline {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ul.navline li {
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.3vw;
  background: #f49420;
  border: none;
  border-radius: 10px;
  color: white;
  justify-content: center;
  opacity: 0.5;
  flex-direction: row;
  padding: 0.8rem 0.5rem;
  display: flex;
  width: 20vw;
  text-align: justify;
}
.lines {
  width: 10.1rem;
  overflow: hidden;
  height: 3vh;
  text-align: justify;
}
ul.navline li.active {
  color: white;
  font-weight: 700;
  opacity: 1;
  width: 40%;
  display: flex;
  width: 40vw;
  text-align: justify;
}
.time-title {
  font-size: 2.5vw;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8vh;
  text-align: justify;
}

@media screen and (max-width: 375px) {
  .timeline {
    height: auto;
    border: none;
    padding: 2rem 1rem;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    margin-top: 50px;
  }

  ul.navline {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ul.navline li {
    list-style: none;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.5rem;
    background: #f49420;
    border: none;
    width: 50vw;
    border-radius: 20px;
    color: white;
    justify-content: center;
    opacity: 0.5;
    flex-direction: row;
    padding: 0.2rem 0.02rem;
    display: flex;
    text-align: justify;
  }
  .lines {
    width: 10.1rem;
    overflow: hidden;
    height: 2.5vh;
    text-align: justify;
  }
  ul.navline li.active {
    color: white;
    font-weight: 700;
    opacity: 1;
    width: 100vw;
    display: flex;
    font-size: 0.7rem;
    border-radius: 5px;
    text-align: justify;
    padding: 0.2rem 0.2rem;
  }
  .time-title {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }
}
@media screen and (max-width: 575px) {
  .timeline {
    height: auto;
    border: none;
    padding: 2rem 1rem;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    margin-top: 50px;
  }

  ul.navline {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ul.navline li {
    list-style: none;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.5rem;
    background: #f49420;
    border: none;
    width: 50vw;
    border-radius: 20px;
    color: white;
    justify-content: center;
    opacity: 0.5;
    flex-direction: row;
    padding: 0.2rem 0.02rem;
    display: flex;
    text-align: justify;
  }
  .lines {
    width: 10.1rem;
    overflow: hidden;
    height: 2.5vh;
    text-align: justify;
  }
  ul.navline li.active {
    color: white;
    font-weight: 700;
    opacity: 1;
    width: 100vw;
    display: flex;
    font-size: 0.7rem;
    border-radius: 5px;
    text-align: justify;
    padding: 0.2rem 0.2rem;
  }
  .time-title {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }
}

.One {
  display: block;
  flex-direction: column;
}
.one-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.one-image > img {
  width: 32vw;
}
.one-desc {
  font-size: 1.5vw;
  line-height: 1.6;
  margin-top: -10%;
  text-align: justify;
}
.one-desc > h5 {
  font-weight: 400;
  text-align: justify;
}
.one-desc > h5 > span {
  color: #3e4194;
  font-weight: 700;
  text-align: justify;
}
.one-desc > h5 > span > span {
  color: #f49420;
  font-weight: 700;
  text-align: justify;
}
@media screen and (max-width: 375px) {
  .One {
    display: block;
    flex-direction: column;
  }
  .one-row {
    display: grid;

    justify-content: center;
    align-items: center;
  }
  .one-image > img {
    width: 90vw;
    height: 221px;
  }
  .one-image {
    order: 1;
  }
  .one-desc {
    font-size: 0.9rem;
    padding: 0rem 1rem;
    margin-top: -19%;
    text-align: justify;
    width: 90%;
  }
  .one-desc > h5 {
    font-weight: 400;
    text-align: justify;
  }
}
@media screen and (max-width: 575px) {
  .One {
    display: block;
    flex-direction: column;
  }
  .one-row {
    display: grid;

    justify-content: center;
    align-items: center;
  }
  .one-image > img {
    width: 90vw;
    height: 221px;
    order: 1;
  }
  .one-image {
    order: 1;
  }
  .one-desc {
    font-size: 0.9rem;
    padding: 0rem 1rem;

    text-align: justify;
    width: 90%;
    order: 2;
  }
  .one-desc > h5 {
    font-weight: 400;
    text-align: justify;
  }
  .mobile {
    order: 1;
  }
}

.Two {
  display: block;
  flex-direction: column;
}
.two-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.two-image > img {
  width: 32vw;

  justify-content: center;
  align-items: center;
}
.two-desc {
  font-size: 1.5vw;
  line-height: 1.6;
  justify-content: center;
  align-items: center;
  margin-top: -20%;
  text-align: justify;
}

.two-desc > h5 {
  font-weight: 400;
  text-align: justify;
}
@media screen and (max-width: 375px) {
  .Two {
    display: block;
    flex-direction: column;
  }
  .two-row {
    display: grid;

    justify-content: center;
    align-items: center;
  }
  .two-image > img {
    width: 90vw;
    height: 221px;
  }
  .two-desc {
    font-size: 0.9rem;
    padding: 0rem 1rem;

    text-align: justify;

    width: 90%;
  }
  .two-desc > h5 {
    font-weight: 400;
    text-align: justify;
  }
}
@media screen and (max-width: 575px) {
  .Two {
    display: block;
    flex-direction: column;
  }
  .two-row {
    display: grid;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  .two-image > img {
    width: 90vw;
    height: 221px;
  }
  .two-desc {
    font-size: 0.9rem;
    padding: 0rem 1rem;

    text-align: justify;

    width: 90%;
  }
  .two-desc > h5 {
    font-weight: 400;
    text-align: justify;
  }
}

.sales-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sale-des {
  text-align: left;
  font-size: 2.5vw;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8vh;
  margin-top: 100px;
}
.v-desc {
  font-size: 1.2vw;
  font-weight: 400;
}
.v-desc > p > span {
  color: #3e4295;
  font-weight: 700;
}

.v-desc > p > span > span {
  color: #f79520;
}
.sales-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6vw;
}
.sales-col1 > img {
  width: 27vw;
}

.second {
  display: block;
  flex-direction: column;
}
.s-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.s-image > img {
  width: 30vw;
}
.s-desc {
  font-size: 1.5vw;
  line-height: 1.6;
  text-align: justify;
  width: 30vw;
}
.s-desc > h5 {
  font-weight: 400;
  text-align: justify;
}
@media screen and (max-width: 375px) {
  .second {
    display: block;
    flex-direction: column;
  }
  .s-row {
    display: grid;

    justify-content: center;
    align-items: center;
  }
  .s-image > img {
    width: 95vw;
    height: 40vh;
  }

  .s-desc {
    font-size: 0.9rem;
    padding: 0rem 2rem;
    line-height: 1.6;
    text-align: justify;
    margin-top: -3rem;
    width: 80%;
  }
  .s-desc > h5 {
    font-weight: 400;
    text-align: justify;
  }
  .mobile2 {
    order: 2;
  }
}
@media screen and (max-width: 575px) {
  .second {
    display: block;
    flex-direction: column;
  }
  .s-row {
    display: grid;

    justify-content: center;
    align-items: center;
  }
  .s-image > img {
    width: 85vw;
    height: 40vh;
    margin-left: 20px;
  }

  .s-desc {
    font-size: 0.9rem;
    padding: 0rem 2rem;
    line-height: 1.6;
    text-align: justify;
    margin-top: -3rem;
    width: 90%;
  }
  .s-desc > h5 {
    font-weight: 400;
    text-align: justify;
  }
  .mobile2 {
    order: 2;
  }
}

.p-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  background-image: url(../../img/Earth.png);
  background-size: 100%;
  background-repeat: no-repeat;
  padding-bottom: 100px;
}
.p-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.col {
  width: 100%;
}
.box {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 45vw;
  padding: 4vw 4vh;
  gap: 1.5rem;
  border: 1px solid white;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.26);
  backdrop-filter: blur(11px);
  justify-content: center;

  z-index: 1;
}
.contact > h1 {
  color: #3e4295;
  font-size: 1.6vw;
  font-weight: 700;
  text-align: justify;
}

.partners > h1 {
  color: #3e4295;
  font-size: 1.6vw;
  font-weight: 700;
  text-align: justify;
}
.partners > p > :nth-child(1) {
  color: #3e4295;
  text-align: justify;
}
.partners > p > span > span {
  color: #f49420;
  text-align: justify;
}

.line :nth-child(2) {
  font-size: 1.2vw;
  font-weight: 500;
  margin-bottom: 2rem;
  align-items: center;
  justify-content: center;
  text-align: justify;
}
.partners > p {
  font-size: 1.2vw;
  font-weight: 500;
  line-height: 1.6;
  text-align: justify;
}
.line :nth-child(1) {
  width: 1.8vw;
  font-size: 1.2vw;
  font-weight: 500;
  margin-right: 0.5rem;
  align-items: center;
  justify-content: center;
  text-align: justify;
}
@media screen and (max-width: 375px) {
  .p-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../img/Earth.png);
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 0;
  }
  .p-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .box {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    align-items: left;
    width: 290px;
    padding: 4vw 4vh;
    gap: 0;

    border: 1px solid white;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.26);
    backdrop-filter: blur(11px);
    justify-content: center;
    z-index: 1;
  }
  .contact > h1 {
    color: #3e4295;
    font-size: 14px;
    font-weight: 700;
    text-align: justify;
  }

  .partners > h1 {
    color: #3e4295;
    font-size: 14px;
    font-weight: 700;
    text-align: justify;
  }
  .partners > p > :nth-child(1) {
    color: #3e4295;
    text-align: justify;
  }
  .partners > p > span > span {
    color: #f49420;
    text-align: justify;
  }

  .line :nth-child(2) {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    align-items: center;
    justify-content: center;
    text-align: justify;
  }
  .partners > p {
    font-size: 12px;
    font-weight: 500;
    text-align: justify;
  }
  .line :nth-child(1) {
    width: 15px;
    font-size: 12px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    text-align: justify;
  }
}
@media screen and (max-width: 575px) {
  .p-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../img/Earth.png);
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 0;
  }
  .p-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .box {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    align-items: left;
    width: 290px;
    padding: 4vw 4vh;
    gap: 0;

    border: 1px solid white;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.26);
    backdrop-filter: blur(11px);
    justify-content: center;
    z-index: 1;
  }
  .contact > h1 {
    color: #3e4295;
    font-size: 14px;
    font-weight: 700;
    text-align: justify;
  }

  .partners > h1 {
    color: #3e4295;
    font-size: 14px;
    font-weight: 700;
    text-align: justify;
  }
  .partners > p > :nth-child(1) {
    color: #3e4295;
    text-align: justify;
  }
  .partners > p > span > span {
    color: #f49420;
    text-align: justify;
  }

  .line :nth-child(2) {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    align-items: center;
    justify-content: center;
    text-align: justify;
  }
  .partners > p {
    font-size: 12px;
    font-weight: 500;
    text-align: justify;
  }
  .line :nth-child(1) {
    width: 15px;
    font-size: 12px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    text-align: justify;
  }
}

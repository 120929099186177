html,
body {
  margin: 0;
  padding: 0;
  font-family: DIN Next LT Arabic;
}

::-webkit-scrollbar {
  display: none;
}

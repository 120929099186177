.f-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ebecf1;
  padding-top: 10vh;
  padding: 10vh;
}
.row-f {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 50vw;
}
.col-f1 > img {
  float: left;
  align-items: center;
  width: 5vw;
}
.col-f2 {
  font-size: 0.9vw;
  text-align: justify;
}
@media screen and (max-width: 375px) {
  .f-wrapper {
    display: grid;

    justify-content: center;
    align-items: center;
    background-color: #ebecf1;

    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .row-f {
    display: grid;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    gap: 1rem;
  }
  .col-f1 > img {
    align-items: center;
    justify-content: center;
    width: 71px;
    margin-left: 20vw;
  }
  .col-f2 {
    font-size: 0.6rem;
    text-align: justify;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 575px) {
  .f-wrapper {
    display: grid;

    justify-content: center;
    align-items: center;
    background-color: #ebecf1;

    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .row-f {
    display: grid;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    gap: 1rem;
  }
  .col-f1 > img {
    align-items: center;
    justify-content: center;
    width: 71px;
    margin-left: 20vw;
  }
  .col-f2 {
    font-size: 0.6rem;
    text-align: justify;
    justify-content: center;
    align-items: center;
  }
}

.tabs {
  height: auto;
  margin-top: 100px;
  border: none;
  padding: 1rem 1rem;
  color: black;
}

ul.nav {
  width: 60vw;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0px;
  border-bottom: 1px solid #283646;
  text-align: justify;
}

ul.nav li {
  padding: 1rem 0;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 1.3vw;
  width: 9.091vw;
  text-align: justify;
}

ul.nav li.active {
  /* text-decoration: underline; */
  /* text-decoration-color: #f49420; */
  /* text-decoration-thickness: 0.2vw; */
  /* text-underline-offset: 2.6vh; */
  color: #f49420;
  font-weight: 700;
  border-bottom: 3px solid #f49420;
}
.outlet {
  width: 75vw;
  margin: 1rem 10vw;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 375px) {
  .tabs {
    margin-top: 50px;
    border: none;
    color: black;
    padding: 1rem 1rem;
  }

  ul.nav {
    width: 90vw;

    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.88px solid #c2c2c2;
    background: #f5f5f5;
    color: black !important;
    text-align: justify;

    margin: 0 0;
  }

  ul.nav li {
    list-style: none;
    color: black !important;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 0.9rem;

    width: 97vw;
    margin: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  ul.nav li.active {
    /* text-decoration: underline; */
    /* text-decoration-color: #f49420; */
    /* text-decoration-thickness: 0.2vw; */
    /* text-underline-offset: 2.6vh; */

    color: white !important;
    font-weight: 700;
    border-bottom: 2.64px solid #f7941d;
    background: #f9ac4f;
    align-items: center;
    justify-content: center;
  }
  .outlet {
    width: 80vw;
    margin: 1rem 1rem;
  }
}
@media screen and (max-width: 575px) {
  .tabs {
    margin-top: 50px;
    border: none;
    color: black;
    padding: 1rem 1rem;
  }

  ul.nav {
    width: 95vw;

    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.88px solid #c2c2c2;
    background: #f5f5f5;
    color: black !important;
    text-align: justify;

    margin: 0 0;
  }

  ul.nav li {
    list-style: none;
    color: black !important;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 0.9rem;

    width: 97vw;
    margin: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  ul.nav li.active {
    /* text-decoration: underline; */
    /* text-decoration-color: #f49420; */
    /* text-decoration-thickness: 0.2vw; */
    /* text-underline-offset: 2.6vh; */

    color: white !important;
    font-weight: 700;
    border-bottom: 2.64px solid #f7941d;
    background: #f9ac4f;
    align-items: center;
    justify-content: center;
  }
  .outlet {
    width: 80vw;
    margin: 1rem 1rem;
  }
}

.a-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 7vw;
}
.s-title {
  text-align: left;
  font-size: 2.5vw;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8vh;

  margin-top: 100px;
}
.a-menu {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  align-items: center;
  font-size: 1.3vw;
  justify-content: center;
  padding: 0;
  text-align: justify;
}
.a-menu > li {
  background-color: #f9f9ff;
  width: 30vw;
  height: 13vh;
  padding-left: 1vw;
  align-items: center;
  justify-content: center;
  text-align: justify;
  color: #787a7d;
  border-radius: 10px;
  border: none;
  text-align: justify;
}

.a-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 32vw;
  height: 55vh;
  background-color: #f9f9ff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  text-align: justify;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}
.list .image-s > img {
  width: 32vw;

  justify-content: center;
  align-items: center;
  object-fit: contain;
}
.list .detail {
  font-size: 1.1vw;
  font-weight: 400;
  line-height: 1.6;
  color: #434343;
  text-align: left;
  width: 30vw;

  align-items: center;
  display: flex;
  text-align: justify;
}
.a-menu li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2vw;
  text-align: justify;
}
.a-menu .active-list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2vw;
  border-radius: 16px;
  background: #3e4295;
  box-shadow: 0px 42px 82px -13px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
  color: white;
  text-align: justify;
}
.a-menu .active-list-item img {
  width: 3vw;
  height: 6.5vh;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}
.a-menu li span img {
  object-fit: contain;
  width: 3vw;
  height: 6.5vh;
}
@media only screen and (max-width: 768px) {
  .a-menu > li {
    width: 30vw;
    height: 8vh;
  }
  .a-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 32vw;
    height: 35vh;
    background-color: #f9f9ff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 8px;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 375px) {
  .a-wrapper {
    display: grid;
    margin-top: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 7vw;
  }
  .s-title {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 5vh;
    margin-top: 50px;
  }
  .a-menu {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    align-items: center;
    font-size: 0.9rem;
    justify-content: center;

    width: 100%;
    text-align: justify;
  }
  .a-menu > li {
    background-color: #f9f9ff;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: justify;
    color: #787a7d;
    border-radius: 10px;
    border: none;
    text-align: justify;
  }

  .a-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 95vw;
    height: 290px;
    background-color: #f9f9ff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    text-align: justify;
  }

  .list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: justify;
  }
  .list .image-s > img {
    width: 90vw;

    justify-content: flex-start;
    align-items: flex-start;
    object-fit: contain;
  }
  .list .detail {
    font-size: 0.9vw;
    font-weight: 400;

    color: #434343;

    width: 50vh;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: justify;
  }
  .a-menu li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2vw;
    height: 10vh;
    text-align: justify;
  }
  .a-menu .active-list-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2vw;
    border-radius: 16px;
    background: #3e4295;
    box-shadow: 0px 42px 82px -13px rgba(0, 0, 0, 0.25);
    transition: all 0.4s ease;
    color: white;
    text-align: justify;
  }
  .a-menu .active-list-item img {
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }
  .a-menu li span img {
    object-fit: contain;
    width: 24px;
    height: 24px;
  }
}
@media screen and (max-width: 575px) {
  .a-wrapper {
    display: grid;
    margin-top: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 7vw;
  }
  .s-title {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 5vh;
    margin-top: 50px;
  }
  .a-menu {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    align-items: center;
    font-size: 0.9rem;
    justify-content: center;

    width: 100%;
    text-align: justify;
  }
  .a-menu > li {
    background-color: #f9f9ff;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: justify;
    color: #787a7d;
    border-radius: 10px;
    border: none;
    text-align: justify;
  }

  .a-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 95%;
    height: 60vh;
    background-color: #f9f9ff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 0px;
    margin-right: 0;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    text-align: justify;
  }

  .list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    gap: 0.5rem;
    width: 90vw;
  }
  .list .image-s > img {
    justify-content: center;
    align-items: center;
    width: 80vw;
    object-fit: contain;
  }
  .list .detail {
    font-size: 0.8rem;
    font-weight: 400;

    color: #434343;
    width: 80vw;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: justify;
  }
  .a-menu li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2vw;
    height: 10vh;
    text-align: justify;
  }
  .a-menu .active-list-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2vw;
    border-radius: 16px;
    background: #3e4295;
    box-shadow: 0px 42px 82px -13px rgba(0, 0, 0, 0.25);
    transition: all 0.4s ease;
    color: white;
    text-align: justify;
  }
  .a-menu .active-list-item img {
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }
  .a-menu li span img {
    object-fit: contain;
    width: 24px;
    height: 24px;
  }
}

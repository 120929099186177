.first {
  display: block;
  flex-direction: column;
}
.f-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.f-image > img {
  width: 30vw;
}
.f-desc {
  font-size: 1.5vw;
  line-height: 1.6;
  text-align: justify;
  width: 30vw;
}
.f-desc > h5 {
  font-weight: 400;
  text-align: justify;
}
@media screen and (max-width: 375px) {
  .first {
    display: block;
    flex-direction: column;
  }
  .f-row {
    display: grid;

    justify-content: center;
    align-items: center;
  }
  .f-image > img {
    width: 95vw;
    height: 40vh;
  }
  .f-desc {
    font-size: 0.9rem;
    padding: 0rem 1rem;
    line-height: 1.6;
    text-align: justify;
    margin-top: -3rem;
    width: 90%;
  }
  .f-desc > h5 {
    font-weight: 400;
    text-align: justify;
  }
}
@media screen and (max-width: 575px) {
  .first {
    display: block;
    flex-direction: column;
  }
  .f-row {
    display: grid;

    justify-content: center;
    align-items: center;
  }
  .f-image > img {
    width: 85vw;
    height: 40vh;
    margin-left: 20px;
  }
  .f-desc {
    font-size: 0.9rem;
    padding: 0rem 1rem;
    line-height: 1.6;
    text-align: justify;
    margin-top: -3rem;
    width: 95%;
  }
  .f-desc > h5 {
    font-weight: 400;
    text-align: justify;
  }
}
